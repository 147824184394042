import "./App.css";
import React from "react";
import { ChatState } from "./context/Context";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { MainPage } from "./pages/Home/MainPage";
import ContactUsForm from "./pages/Forms/ContactUsForm";
import Blog from "./pages/Blog/Blog";
import { Collaboration } from "./pages/Collaboration Page/Collaboration";
import { Partners } from "./pages/Partners Page/Partners";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/contact" element={<ContactUsForm />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/collaboration" element={<Collaboration />} />
          <Route path="/partners" element={<Partners />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
