import React, { lazy, Suspense, useEffect, useState } from "react";
import styles from "./MainPage.module.css";
import { ChatState } from "../../context/Context";
import { Maintenance } from "../../components/Maintenance/Maintenance";
import Loading from "../../components/Loading/Loading";
import Test3 from "../../components/Tests/Test3";

// Lazily load components
const Navbar = lazy(() => import("../../components/Navbar/Navbar"));
const Banner = lazy(() => import("../../components/Banner/Banner"));
const Info = lazy(() => import("../../components/Info/Info"));
const Screen = lazy(() => import("../../components/Screen/Screen"));
const Associates = lazy(() => import("../../components/Associates/Associates"));
const Usp = lazy(() => import("../../components/Usp/Usp"));
const UspVideo = lazy(() => import("../../components/Usp/UspVideo"));
const Journey = lazy(() => import("../../components/Journey/Journey"));
const Team = lazy(() => import("../../components/Team/Team"));
const Footer = lazy(() => import("../../components/Footer/Footer"));
const VideoMain = lazy(() => import("../../components/Video/VideoMain"));
const Test = lazy(() => import("../../components/Tests/Test"));
const Test2 = lazy(() => import("../../components/Tests/Test2"));

export const MainPage = () => {
  const { containerRef, underMaintenance, setUnderMaintenance } = ChatState();
  const [notify, setNotify] = useState(true);

  // this resizeListener will show undermaintenance for all mobile views
  useEffect(() => {
    const resizeListener = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      if (width < 376) {
        setUnderMaintenance(true);
      } else if (width > 2045) {
        setUnderMaintenance(true);
      } else {
        setUnderMaintenance(false);
      }
    }

    resizeListener()
    window.addEventListener("resize", resizeListener);
    return () => window.removeEventListener("resize", resizeListener);
  }, [])
  
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotify(false);
    }, 5500);

    // Clean up the timeout when the component unmounts
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <Suspense fallback={<Loading />}>
        {underMaintenance && <Maintenance />}
        {!underMaintenance && (
          <>
            {notify && (
              <div className={styles.notifyContainer}>
                <div className={styles.notify}>
                  <p>PLEASE VIEW OUR WEBSITE IN LIGHT</p>
                  <p> MODE FOR THE BEST EXPERIENCE</p>
                </div>
              </div>
            )}
            <Navbar />
            <Banner />
            <VideoMain />
            <Test />
                <Test2 />
                <Test3 />
            <div style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  transition: "top 0.7s ease-out",
                  width: "100%",
                }}
                ref={containerRef}
              >
                <Info />
                <Screen />
                <Usp />
                <UspVideo />
                
                <Associates />
                <Journey />
                <Team />
                <Footer />
              </div>
            </div>
          </>
        )}
      </Suspense>
    </>
  );
};
