import { createContext, useContext, useState, useEffect, useRef } from "react";

const ChatContext = createContext();

const ChatProvider = ({ children }) => {
  const [menu_clicked, setMenuClicked] = useState(false);
  const [underMaintenance, setUnderMaintenance] = useState(false);
  const containerRef = useRef(null);
  const [navbarView, setNavbarView] = useState({uspView : false, videoSectionView : false})

  const box_ref = useRef(null);

  return (
    <ChatContext.Provider value={{ containerRef, underMaintenance, setUnderMaintenance, menu_clicked, setMenuClicked, box_ref, navbarView, setNavbarView }}>
      {children}
    </ChatContext.Provider>
  );
};

export default ChatProvider;
export const ChatState = () => {
  // JSON.stringify(localStorage.setItem("temporary", useContext(ChatContext)))
  return useContext(ChatContext);
};
