import React from "react";
import styles from "./Test3.module.css";

const Test3 = () => {
  return (
    <>
      <div className={styles.Landscapeview}>
        <div className={styles.Topbox}>
          HOW WE MAKE A DIFFERENCE ?
        </div>
        <div className={styles.Bottombox}>
          <div className={styles.Leftbox}>
            <img
              src={`${process.env.PUBLIC_URL}/Tests-Images/Test3-images/Kiosk.png`}
              draggable={false}
              className={styles.kiosk}
            />
          </div>
          <div className={styles.Rightbox}>
            <div className={styles.container}>
              <div className={styles.subcontainer}>
                <div className={styles.Topbox1}>
                <div className={styles.Box1}>
                  <div className={styles.Topheader}>
                  20 + HEALTH Tests <br/>
                  in just 10 minutes
                  </div>
                  <div className={styles.Bottomheader}>
                  Comprehensive Diagnostics , 
                  faster than ever !
                  </div>
                </div>
                <div className={styles.Box2}>
                <div className={styles.Topheader}>
                Portable Clinic <br/> 
                Anywhere Anytime
                  </div>
                  <div className={styles.Bottomheader}>
                  Your diagnostic lab , right at your fingertips .
                  </div>
                </div>
                </div>
                <div className={styles.Bottombox1}>
                <div className={styles.Box3}>
                <div className={styles.Topheadera}>
                High Quality Diagnostics , <br/>
                1/10th the Cost
                  </div>
                  <div className={styles.Bottomheader}>
                  Premium healthcare , Affordable Price !
                  </div>
                </div>
                <div className={styles.Box4}>
                <div className={styles.Topheadera}>
                Qualified Doctors , <br/>
                Professional Care
                  </div>
                  <div className={styles.Bottomheader}>
                  Connect with Experts Instantly via Telemedicine .
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ----------------Mobile Responsive----------------------- */}
      <div className={styles.Potraitview}>
      <div className={styles.Topbox}>
          HOW WE MAKE A <br/>
          DIFFERENCE ?
        </div>
        <div className={styles.Bottombox}>
          <div className={styles.Leftbox}>
            <img
              src={`${process.env.PUBLIC_URL}/Tests-Images/Test3-images/Kiosk.png`}
              draggable={false}
              className={styles.kiosk}
            />
          </div>
          <div className={styles.Bottomboxm}>
          <div className={styles.subcontainer}>
            <div className={styles.Content1}>
            <div className={styles.Topheader}>
                  20 + HEALTH Tests
                  in just 10 minutes
                  </div>
                  <div className={styles.Bottomheader}>
                  Comprehensive Diagnostics , 
                  faster than ever !
                  </div>
            </div>
            <div className={styles.Content2}>
            <div className={styles.Topheader}>
                Portable Clinic 
                Anywhere Anytime
                  </div>
                  <div className={styles.Bottomheader}>
                  Your diagnostic lab , right at your fingertips .
                  </div>
            </div>
            <div className={styles.Content3}>
            <div className={styles.Topheadera}>
                High Quality Diagnostics , 
                1/10th the Cost
                  </div>
                  <div className={styles.Bottomheader}>
                  Premium healthcare , Affordable Price !
                  </div>
            </div>
            <div className={styles.Content4}>
            <div className={styles.Topheadera}>
                Qualified Doctors , 
                Professional Care
                  </div>
                  <div className={styles.Bottomheader}>
                  Connect with Experts Instantly via Telemedicine .
                  </div>
            </div>
          </div>
          </div>
          </div>
      </div>
    </>
  );
};

export default Test3;
