import React from 'react'
import styles from './Loading.module.css'
import Loader from "react-js-loader";

const Loading = () => {
    return (
        <div className={styles.loadingContainer}>
            <Loader type="spinner-cub" bgColor={"rgba(0, 0, 0, 0.8)"} color={"white"} title={"Please Wait"} size={100} />
        </div>
    )
}

export default Loading